var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"mb-5"},[_vm._v("Equação de ganho de peso")]),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersAnimal,"items":_vm.params_animals_advanced,"search":_vm.search,"items-per-page":10,"loading":_vm.$apollo.loading,"sort-by":"genetic_pattern"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","dark":"","to":"/parametros/animal/nova/tabela"}},[_vm._v(" Novo ")])],1)]},proxy:true},{key:"item.genetic_pattern",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.geneticPatterns[item.genetic_pattern])+" ")]}},{key:"item.category_animal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.categoriesAnimal[item.category_animal])+" ")]}},{key:"item._actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"primary","to":("/parametros/animal/" + (item.genetic_pattern) + "/" + (item.category_animal))}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"primary","to":("/parametros/animal/" + (item.genetic_pattern) + "/" + (item.category_animal) + "?clone=true")}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-duplicate")])],1)]}}],null,true)},[_c('span',[_vm._v("Clonar")])]),_c('confirmation',{on:{"success":function () { return _vm.remove(item.genetic_pattern, item.category_animal); }}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"error"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Excluir")])])],1)]}}],null,true)})],1),_c('h1',{staticClass:"my-5"},[_vm._v("Coeficientes padrões de cálculo")]),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.params,"search":_vm.search,"items-per-page":10,"loading":_vm.$apollo.loading,"sort-by":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Procurar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.value))+" ")]}},{key:"item._actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","icon":"","color":"primary","to":("/parametros/" + (item.id))}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }