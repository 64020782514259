<script>
import Confirmation from '@/components/Confirmation.vue'
import {geneticPatternsDataType, categoriesAnimalDataType} from '@/utils/enums'
import gql from 'graphql-tag';
import Swal from 'sweetalert2';

export default {
  components: {
    Confirmation
  },

  apollo: {
    params: {
      query: gql`
        query Params {
          params {
            id
            info
            value
          }
        }
      `,

      fetchPolicy: 'cache-and-network',
    },

    params_animals_advanced: {
      query: gql`
        query params_animals_advanced {
          params_animals_advanced(order_by: {genetic_pattern: asc, category_animal: asc}) {
            genetic_pattern
            category_animal
            qtd
          }
        }
      `,

      fetchPolicy: 'cache-and-network',
    },
  },

  data() {
    return {
      search: '',
      geneticPatterns: geneticPatternsDataType,
      categoriesAnimal: categoriesAnimalDataType,
      headersAnimal: [
        {
          text: 'Padrão genético',
          value: 'genetic_pattern',
        },
        {
          text: 'Categoria Animal',
          value: 'category_animal',
        },
        {
          text: 'Faixas de peso',
          value: 'qtd',
        },
        {
          text: 'Ações',
          value: '_actions',
          sortable: false,
          align: 'end',
        },
      ],

      headers: [
        {
          text: 'Parâmetro',
          value: 'id',
        },
        {
          text: 'Informações',
          value: 'info',
        },
        {
          text: 'Valor',
          value: 'value',
        },
        {
          text: 'Ações',
          value: '_actions',
          sortable: false,
          align: 'end',
        },
      ],
    };
  },

  methods:{
    async remove(genetic_pattern, category_animal) {
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($genetic_pattern: String! $category_animal: String! $date: timestamptz) {
              update_params_animals(
                where: {
                  genetic_pattern: {_eq: $genetic_pattern}, 
                  category_animal: {_eq: $category_animal},
                }, 
                _set: {
                  deleted_at: $date
                }) {
                affected_rows
              }
            }
          `,
          variables: {
            genetic_pattern,
            category_animal,
            date: new Date()
          },
        });
        Swal.fire({
          icon: 'success',
          text: 'Removido com sucesso!',
          position: 'top-end',
          toast: true,
          showConfirmButton: false,
          timer: 3000,
        });
        this.$apollo.queries.params_animals_advanced.refresh()
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>

<template>
  <div>
    <h1 class="mb-5">Equação de ganho de peso</h1>
    
    <v-card>
      <v-data-table
        :headers="headersAnimal"
        :items="params_animals_advanced"
        :search="search"
        :items-per-page="10"
        :loading="$apollo.loading"
        sort-by="genetic_pattern"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer />
            <v-btn color="primary" dark to="/parametros/animal/nova/tabela"> Novo </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.genetic_pattern`]="{ item }">
          {{ geneticPatterns[item.genetic_pattern] }}
        </template> 
        <template v-slot:[`item.category_animal`]="{ item }">
          {{ categoriesAnimal[item.category_animal] }}
        </template> 
        <template v-slot:[`item._actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn text icon color="primary" :to="`/parametros/animal/${item.genetic_pattern}/${item.category_animal}`" v-on="on">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn text icon color="primary" :to="`/parametros/animal/${item.genetic_pattern}/${item.category_animal}?clone=true`" v-on="on">
                <v-icon small>mdi-content-duplicate</v-icon>
              </v-btn>
            </template>
            <span>Clonar</span>
          </v-tooltip>
          <confirmation @success="() => remove(item.genetic_pattern, item.category_animal)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn text icon color="error" v-on="on">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Excluir</span>
            </v-tooltip>
          </confirmation>
        </template>
      </v-data-table>
    </v-card>
    
    <h1 class="my-5">Coeficientes padrões de cálculo</h1>

    <v-card>
      <v-data-table
        :headers="headers"
        :items="params"
        :search="search"
        :items-per-page="10"
        :loading="$apollo.loading"
        sort-by="id"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Procurar"
              single-line
              hide-details
            />
          </v-toolbar>
        </template>
        <template v-slot:[`item.value`]="{ item }">
          {{ item.value | number }}
        </template> 
        <template v-slot:[`item._actions`]="{ item }">
          <v-btn text icon color="primary" :to="`/parametros/${item.id}`">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
